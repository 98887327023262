html {
    height:100%;
}

body {
    margin: 0;
    width: 100%;
    min-height:100%;
    height: auto !important;
    height: 100%;
    font-family: 'Sora', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: repeating-linear-gradient(
        0,
        #da2514,
        #c25e12 487px,
        #da2514 679px,
        #c25e12 20px
    );
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
