.pokedex-container {
    height: 100%;
    width: 100%;
}


.nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.pokemodal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 150px;
}

.pokedex-button {
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;

    border: none;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 500px) {
        font-size: 12px;
    }
}

.pokedex-image {
    max-width: 80px;
    max-height: 80px;
}

.tera-raid-container {
    .pokemon-input {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
    }

    .type-list {
        overflow: visible;
        flex-wrap: wrap;
        justify-content: center;

        div {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            .type-name {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                margin-bottom: 10px;
                height: 30px;

                ul {
                    margin-bottom: 0;
                }


            }
        }

    }
}

.pokedex-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-items: center;
    row-gap: 12px;
    padding-bottom: 12px;

    @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
