.party-guesses,
.guesses {
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.game-options {
    display: flex;
    flex-direction: column;
}

.party-guesses-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.party-types {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
}

.party-slot {
    width: 150px;
    height: 100px;
}

.types-container {
    width: fit-content;
}

.party-container {
    width: 100%;
}

.game-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.game-input,
.game-button {
    height: 40px;
    padding: 0 10px;
    font-size: 16px;
}

.message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.game-answer,
.game-hint {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    display: block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    object-fit: contain;
}

.game-hint {
    filter: brightness(0);
}

.game-hint-button {
    margin-bottom: 10px;
}

.game-reset {
    margin-top: 20px;
}

.game-reveal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-party {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
    }
}

h2 {
    margin-top: 20px;
}

.game-filter {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}

.show-filter {
    max-height: fit-content;
    transition: max-height 0.25s ease-in;
    overflow: inherit;
}

.filter-button {
    @media (min-width: 500px) {
        display: none;
    }
}

.leaderboard-table {
    width: 100%;
    td {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis ellipsis;
        max-width: 175px;
    }
}

.start-buttons {
    width: 100%;
    padding-bottom: 20px;

    @media (max-width: 500px) {
        button {
            font-size: 12px;
        }
    }
}


.toast-container {
    z-index: 9999;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
}

.grid {
    width: 100%;
    height: 100%;
}

.info {
    background-color: gray;
}

.filter {
    padding-bottom: 20px;
}

.top-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
}

.filter-row {
    display: grid;
    grid-template-columns: 0.33fr 0.66fr;

    p {
        width: 100%;
    }

    .type-filter {
        width: 100%;
    }

    @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.bst {
    display: flex;
    justify-content: space-around;
}
