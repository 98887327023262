.guess {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 100px;
    border-bottom: 1px solid black;
    border-radius: 4px;
    position: relative;

    @media (min-width: 500px) {
        flex-direction: row;
        min-height: 85px;
    }
}

.name {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    width: 165px;
    height: 75px;
    align-items: center;
    justify-content: center;

    .game-answer {
        height: 55px;
        margin: 0;
        object-fit: contain;
    }
}

.correct-type {
    color: white;
    background-color: green;
}

.type-list-item.bst-type {
    width: 120px !important;
    min-width: 120px !important;
}

.almost-type {
    color: white;
    background-color: orange;
}

.absent-type {
    color: white;
    background-color: gray;
}

.mono-type {
    color: white;
    background-color: black;
}

.correct {
    color: green;
}

.almost {
    color: orange;
}

.absent {
    color: gray;
}

.generation {
    border: 1px solid black;
    border-radius: 50% 50%;
    height: 60px;
    width: 60px;
    background-color: gray;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    box-shadow: 0px 1px 0px black, inset 0px 1px 0px white;

    &.correct {
        background-color: green;
    }

    &.almost {
        background-color: orange;
    }
}

.correct-slot {
    border: 5px solid green;
}

.almost-slot {
    border: 5px solid orange;
}

.absent-slot {
    border: 5px solid gray;
}

.types-container {
    display: flex;
    flex-direction: column;

    .type-list {
        margin-bottom: 0;
    }
}

.type-list {
    display: flex;
    width: 100%;

    tr {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    td, th {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        width: 75%;


        &:nth-child(2) {
            min-width: 45%;
        }
    }

    &-item {
        color: white;
        font-weight: 500;
        height: fit-content;
        width: 85px;
        min-width: 85px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid black;
        border-radius: 6px;
        margin: 5px;
        box-shadow: 0px 2px 0px black, inset 0px 1px 0px white;
        text-shadow: 1px 2px gray;

    }

    & .excluded {
        color: white;
        background-color: black;
        display: flex;
    }
}

.type-row {
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    padding: 0;

    @media (min-width: 500px) {
        flex-wrap: wrap;
        overflow-x: auto;
        justify-content: center;
    }

    &-item {
        color: white;
        font-weight: 500;
        height: 30px;
        width: 85px;
        min-width: 85px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid black;
        border-radius: 6px;
        margin: 5px;
        box-shadow: 0px 2px 0px black, inset 0px 1px 0px white;
        text-shadow: 1px 2px gray;

    }

    & .excluded {
        color: white;
        background-color: black;
        display: flex;
    }
}

.evolution {
    display: flex;
    gap: 4px;

    @media (min-width: 500px) {
        flex-direction: column;
    }
}

.base-total {
    min-width: 190px;
}

.miss {
    filter: grayscale(1);
}

.normal {
    background-color: #a7a777;
}

.fighting {
    background-color: #c03028;
}

.flying {
    background-color: #9a87c8;
}

.poison {
    background-color: #92578c;
}

.ground {
    background-color: #e2c269;
}

.rock {
    background-color: #b8a038;
}

.bug {
    background-color: #a7b721;
}

.ghost {
    background-color: #6f5898;
}

.steel {
    background-color: #b7b7cf;
}

.fire {
    background-color: #ee7e2f;
}

.water {
    background-color: #6790ef;
}

.grass {
    background-color: #8ed755;
}

.electric {
    background-color: #f5cd2b;
}

.psychic {
    background-color: #fc5689;
}

.ice {
    background-color: #98d8d8;
}

.dragon {
    background-color: #7038f7;
}

.dark {
    background-color: #6f5747;
}

.fairy {
    background-color: #ff64d6;
}

.x {
    background-color: darkslategrey;
}
