.container {
    text-align: center;
    margin-top: 40px;
    background-color: white;
    border-radius: 40px;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;

    h1 {
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.logo {
    width: 275px;
    padding: 20px 0;
}

.select-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;

    @media (min-width: 500px) {
        width: unset;
    }

    &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        height: 40px;
        max-width: 140px;
        min-width: 110px;
        border: 1px solid black;
        border-radius: 4px;
        margin: 2px;
        background-color: #3e8bd1;

        @media (min-width: 500px) {
            min-width: 120px;
            max-width: 200px;
        }

        &-button {
            background: none;
            border: none;
            width: 100%;
            height: 100%;
            color: whitesmoke;
            font-size: 14px;

            @media (min-width: 500px) {
                font-size: 16px;
            }
        }
    }

    .active {
        background-color: #3e8ad183;

        button {
            color: white;
            text-shadow: 1px 1px black;
        }
    }
}

.instructions {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
}

.login {
    position: absolute;
    top: 20px;
    left: 20px;
    background: none;
    border: none;
}
